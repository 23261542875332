import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import titleMixin from '@core/mixins/titleMixin'
Vue.mixin(titleMixin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Websocket
import VueEcho from 'vue-echo-laravel';
window.io = require('socket.io-client');
Vue.use(VueEcho, {
  broadcaster: 'socket.io',
  host: process.env.WEBSOCKET_DOMAIN,
});

// Maps
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
